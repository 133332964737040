body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.btn-primary,
.btn-outline-primary {
  background-color: #f0c040;
  color: #000000;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rating span {
  color: #ffc000;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}

img {
  height: 200px;
  width: 200px;
}

@media (min-width: 576px) {
  img {
    height: 200px;
    width: 200px;
  }
}

@media (min-width: 768px) {
  img {
    height: 200px;
    width: 200px;
  }
}

@media (min-width: 992px) {
  img {
    height: 250px;
    width: 250px;
  }
}

@media (min-width: 1200px) {
  img {
    height: 250px;
    width: 250px;
  }
}
.product-info {
  padding: 1rem;
}

.img-thumbnail {
  height: 80px;
  width: 80px;
}

.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}

.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

.text-bold {
  font-weight: bold;
}

.Toastify__toast-body {
  white-space: pre-line;
}

/* sidebar */

.navbar-brand {
  font-weight: bold;
}

.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}

.active-cont {
  margin-left: 240px;
}

.active-nav {
  left: 0;
}

/* end sidebar */
